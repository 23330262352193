/* Example CSS */
.slick-arrow {
    font-size: 18px;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    border: none;
    border-radius: 100%;
    outline: none;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  