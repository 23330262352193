@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  height: 10vh;
  box-shadow: rgba(50, 50, 93, 0.23) 0px 30px 30px -20px;

  box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.logo {
  font-size: 30px;
}
.nav-links {
  display: flex;
  justify-content: flex-end;
  width: 75%;
}

.nav-links li {
  color: black;
  font-size: 20px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.hero {
  text-align: center;
  margin-top: 15%;
}
.hero h1 {
  text-align: center;
  font-size: 80px;
}
.mobile-menu-icon {
  display: none;
}
button {
  border: none;
  text-decoration: none;
  font-size: 20px;
  background: none;
}
@media (max-width: 768px) {
  .logo {
    display: flex;
    position: absolute;
    top: 15px;
    left: 35px;
  }
  .nav-links {
    display: none;
  }
  .navbar{
    padding: 20px;
    height: 7vh;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    right: 2%;
    top: 58px;
    transition: all 0.5s ease-in-out;
    width: 30%;
    background-color: white;
    border: 1px solid #777;
    z-index: 100;
  }
  ul li {
    padding: 15px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid #777;
    text-align: center;
  }
  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 30px;
  }
  .hero h1 {
    font-size: 50px;
  }
}